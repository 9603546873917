import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import Img from "gatsby-image";

import { BREAKPOINTS, COLORS } from "../../ui/variables";
import Heading2 from "../../ui/Heading2";
import { Category, LearningCenterPost } from "../../pages/learning-center";
import ParagraphLarge from "../../ui/ParagraphLarge";
import { ReactComponent as ArrowRightIco } from "../../images/icons/arrow-right.svg";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;

  @media ${BREAKPOINTS.MOBILE} {
    align-items: center;
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
  max-width: 44%;

  @media ${BREAKPOINTS.TABLET} {
    max-width: 50%;
  }

  @media ${BREAKPOINTS.MOBILE} {
    max-width: 100%;
  }
`;

const ImageWrap = styled.div`
  @media ${BREAKPOINTS.MOBILE} {
    max-width: 610px;
    width: 100%;
    order: -1;
    margin: 40px 0;
  }
`;

const Image = styled.div`
  position: relative;
  width: 560px;
  padding: 40px 40px 0 0;
  margin-left: 24px;

  @media ${BREAKPOINTS.TABLET} {
    width: 369px;
    padding: 20px 20px 0 0;
  }

  @media ${BREAKPOINTS.MOBILE} {
    width: 100%;
    margin-left: 0;
  }
`;

const OrangeBox = styled.div`
  background: ${COLORS.ORANGE};
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const FloatingImage = styled(Img)`
  width: 100%;
  z-index: 2;
  position: relative;
`;

interface Props {
  post: LearningCenterPost;
}

const Feature = ({ post }: Props) => {
  return (
    <Container>
      <LeftContent>
        <Category>{post.category.name}</Category>
        <Heading2>{post.title}</Heading2>
        <ParagraphLarge>{post.featureText}</ParagraphLarge>
        <Link
          css={css`
            margin-bottom: 32px;
            display: block;
          `}
          to={post.path}
        >
          Read more{" "}
          <ArrowRightIco
            width="14"
            height="11"
            css={css`
              fill: ${COLORS.ORANGE};
            `}
          />
        </Link>
      </LeftContent>

      <ImageWrap>
        <Image>
          <FloatingImage
            fluid={post.featureImage.fluid}
            alt={post.featureImage.title}
          />
          <OrangeBox />
        </Image>
      </ImageWrap>
    </Container>
  );
};

export default Feature;
