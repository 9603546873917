import React from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { LearningCenterCategoryQuery } from "../../graphql-types";

import { BREAKPOINTS, COLORS } from "../ui/variables";
import Feature from "../components/learning-center/Feature";
import Posts from "../components/learning-center/Posts";
import Heading1 from "../ui/Heading1";
import CategoryNavigation from "../components/learning-center/CategoryNavigation";
import ParagraphExLarge from "../ui/ParagraphExLarge";

const Container = styled.div`
  max-width: 1242px;
  margin: auto;
  padding: 0 40px;

  @media ${BREAKPOINTS.MOBILE} {
    padding: 0 20px;
  }
`;

const Title = styled(Heading1)`
  margin-top: 80px;
`;

const Navigation = styled.div`
  max-width: 1242px;
  margin: auto;
  padding: 0 40px;

  @media screen and (max-width: 1242px) {
    padding: 0;
  }
`;

export const Category = styled.span`
  text-transform: uppercase;
  color: ${COLORS.DARK_GRAY};
  font-size: 14px;
`;

export const pageQuery = graphql`
  query LearningCenterCategory($slug: String) {
    contentfulLearningCenterCategory(urlSlug: { eq: $slug }) {
      name
      urlSlug
    }
    contentfulLearningCenterConfig {
      displayCategories {
        urlSlug
        name
      }
    }
    allContentfulLearningCenterArticle(
      filter: { categories: { elemMatch: { urlSlug: { eq: $slug } } } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        title
        urlSlug
        featureImage {
          title
          fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        featureText {
          featureText
        }
        categories {
          urlSlug
        }
      }
    }
  }
`;

interface Props {
  data: LearningCenterCategoryQuery;
  pageContext: {
    slug: string;
  };
}

const LearningCenterCategory = ({ data, pageContext }: Props) => {
  const {
    contentfulLearningCenterConfig: config,
    allContentfulLearningCenterArticle: articles,
    contentfulLearningCenterCategory: category,
  } = data;

  const posts = articles.nodes.map(post => ({
    title: post.title,
    featureText: post.featureText.featureText,
    path: `/learning-center/${post.categories[0].urlSlug}/${post.urlSlug}`,
    featureImage: {
      fluid: post.featureImage.fluid,
      title: post.featureImage.title,
    },
    category: {
      name: category.name,
    },
  }));
  const feature = posts[0];

  const navigationCategories = config.displayCategories.map(category => ({
    name: category.name,
    urlSlug: category.urlSlug,
  }));

  return (
    <Layout>
      <SEO title={category.name} />
      <Navigation>
        <CategoryNavigation
          categories={navigationCategories}
          activeSlug={pageContext.slug}
        />
      </Navigation>
      <Container>
        <Title>{category.name}</Title>
        {posts.length === 0 && (
          <ParagraphExLarge>
            There are no articles in this category.
          </ParagraphExLarge>
        )}
        {posts.length > 0 && <Feature post={feature} />}
        {posts.length > 1 && <Posts posts={posts.slice(1)} />}
      </Container>
    </Layout>
  );
};

export default LearningCenterCategory;
